import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import {handleInnerNavigation} from "../helpers";
import Carousel from 'react-bootstrap/Carousel'
import styles from '../scss/page.scss'


class FleaMarketPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Flea Market | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.ogImage = this.props.data.ogImage.childImageSharp.fluid

    this.seo = {
      title: title,
      meta: {
        title: title,
        description: "Join the Flea Market! Earn points, get freebies for your fur babies, discounts on products and much more! Don't miss out. Join now for free!",
        image: this.ogImage
      },
      children: [
        <link rel="canonical" href={this.pageUrl} key='canonical-url'/>
      ]
    }

    this.state = {
      offset: 60
    }
  }

  render() {

    return (
      <Layout containerClassName={`page page-flea-market`} bodyClassName="chat-hidden"
              location={this.props.location} hideFooter={true} hideHeader={true}>
        <SEO { ...this.seo }>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>

        <section className="join-flea-market-section py-0">
          <div className="row m-0">
            <div className="col-12 col-md-6 dogs-flea-market-content">
              <h1 className="text-center text-md-left">Join the Flea Market <br/>to Start Earning <br className="d-md-none"/>Points</h1>
              <div className="col-10 col-md-12 mx-auto text-center text-md-left subtitle p-0 mt-3">Get freebies for your furbaby, discounts on products <br className="d-none d-lg-block"/>and more!</div>
              <a href="https://portal.honestpaws.com/account/register#/" target="_blank" className="btn btn-primary col-12 col-md-8 col-lg-6 my-4 my-md-3">Join Now</a>
              <p className="text-center text-md-left">Already a Loyalty Member? <a href="https://portal.honestpaws.com/account/login" target="_blank"><b>Log in.</b></a></p>
            </div>
            <div className="col-12 col-md-6 dogs-flea-market-img">
              <a href="#earn-now-section" className="down-arrow" onClick={(e) => handleInnerNavigation(e, -this.state.offset)}></a>
            </div>
          </div>
        </section>

        <section className="earn-now-section" id="earn-now-section">
          <div className="container">
            <div className="row m-0">
              <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
                <div className="content-box text-center">
                  <h2>Earn Now</h2>
                  <div className="stars-gif"></div>
                  <h3>Score Points</h3>
                  <p>You can earn points up to 8 points on purchases, auto-refill subscriptions, social posts and even for signing up.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mt-4 mt-md-0">
                <div className="content-box text-center">
                  <h2>Redeem Later</h2>
                  <div className="gift-gif"></div>
                  <h3>Fun Rewards</h3>
                  <p>Redeem your points at the Honest Paws Flea Market. Get discounts, free product, and more!</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="member-only-perks-section p-0">
          <div className="row m-0">
            <div className="col-12 col-lg-6 member-gif-bg">
              <div className="member-gif"></div>
            </div>
            <div className="col-12 col-lg-6 member-content py-5 row m-0">
              <h2 className="col-12 mb-5 text-center text-lg-left">Member Only Perks</h2>

              <div className="col-lg-12 col-md-6 d-none d-md-flex">
                <div className="box-content">
                  <div className="white-circle d-flex justify-content-center align-items-center ">
                    <img src="/img/landing-pages/flea-market/VIP icon.svg" alt="Vip Icon"/>
                  </div>
                  <div className="col-9 col-lg-10">
                    <h3>VIP Access</h3>
                    <p>Redeem points for full-sized products, samples, and other Honest Paws goodies for your pet.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-6 d-none d-md-flex">
                <div className="box-content">
                  <div className="white-circle d-flex justify-content-center align-items-center ">
                    <img src="/img/landing-pages/flea-market/Vector.svg" alt="Spotlight Icon"/>
                  </div>
                  <div className="col-9 col-lg-10">
                    <h3>Monthly Spotlight</h3>
                    <p>Monthly features on products, reward redemption offers, or limited-time promotions.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-6 d-none d-md-flex">
                <div className="box-content">
                  <div className="white-circle d-flex justify-content-center align-items-center ">
                    <img src="/img/landing-pages/flea-market/Vector-1.svg" alt="Order Icon"/>
                  </div>
                  <div className="col-9 col-lg-10">
                    <h3>Easy Ordering</h3>
                    <p>You can track orders, save your order history, or repeat old orders with a few quick clicks.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-6 d-none d-md-flex">
                <div className="box-content">
                  <div className="white-circle d-flex justify-content-center align-items-center ">
                    <img src="/img/landing-pages/flea-market/Shipping Icon.svg" alt="Shipping Icon"/>
                  </div>
                  <div className="col-9 col-lg-10">
                    <h3>Free Shipping</h3>
                    <p>Never pay for shipping when you reach our “Pack Leader” tier by auto-refilling your Honest Paws product.</p>
                  </div>
                </div>
              </div>

              <div className="d-md-none member-perks-list">
                <Carousel controls={false} interval={3000}>
                  <Carousel.Item>
                    <div className="box-content">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center ">
                        <img src="/img/landing-pages/flea-market/VIP icon.svg" alt="Vip Icon"/>
                      </div>
                      <div className="col-8">
                        <h3>VIP Access</h3>
                        <p>Redeem points for full-sized products, samples, and other Honest Paws goodies for your pet.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="box-content">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center ">
                        <img src="/img/landing-pages/flea-market/Vector.svg" alt="Spotlight Icon"/>
                      </div>
                      <div className="col-8">
                        <h3>Monthly Spotlight</h3>
                        <p>Monthly features on products, reward redemption offers, or limited-time promotions.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="box-content">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center ">
                        <img src="/img/landing-pages/flea-market/Vector-1.svg" alt="Order Icon"/>
                      </div>
                      <div className="col-8">
                        <h3>Easy Ordering</h3>
                        <p>You can track orders, save your order history, or repeat old orders with a few quick clicks.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="box-content">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center ">
                        <img src="/img/landing-pages/flea-market/Shipping Icon.svg" alt="Shipping Icon"/>
                      </div>
                      <div className="col-8">
                        <h3>Free Shipping</h3>
                        <p>Never pay for shipping when you reach our “Pack Leader” tier by auto-refilling your Honest Paws product.</p>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>

            </div>
          </div>
        </section>

        <section className="flea-market-tiers-section">
          <div className="container">
            <div className="row m-0 text-center">
              <h2 className="col-12">Flea Market Tiers</h2>
              <div className="col-12 col-10 mx-auto mb-5 description">Whether you're a Litter Mate earner or an Animal A-Lister, racking up points is easy. Members <br className="d-none d-lg-block"/> also get points for reviewing items,<br className="d-none d-md-block d-lg-none"/> sharing Honest Paws on social media,<br className="d-md-none"/> and more.</div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div className="tiers-box">
                  <div className="tier-title">Litter Mates</div>
                  <div className="img-price-container">
                    <div className="white-circle mx-auto my-3 d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/PawPrint.svg" alt="Paw Print"/>
                    </div>
                    <div className="price-range-box">
                      $0.00 - $349.99
                    </div>
                  </div>
                </div>
                <div className="tiers-box-description">Spend less than $350, and you'll earn 5 points per dollar spent.</div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div className="tiers-box">
                  <div className="tier-title">Pampered Pets</div>
                  <div className="img-price-container">
                    <div className="white-circle mx-auto my-3 d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/PamperdPets.svg" alt="Pamperd Pets"/>
                    </div>
                    <div className="price-range-box">
                      $350.00 - $699.99
                    </div>
                  </div>
                </div>
                <div className="tiers-box-description">$350 —$700 earn 6 points per <br className="d-md-none"/>dollar spent.</div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div className="tiers-box">
                  <div className="tier-title">Animal A-Listers</div>
                  <div className="img-price-container">
                    <div className="white-circle mx-auto my-3 d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Star.svg" alt="Star"/>
                    </div>
                    <div className="price-range-box">
                      $700+
                    </div>
                  </div>
                </div>
                <div className="tiers-box-description">Over $700 earn 7 points per <br className="d-md-none"/>dollar spent.</div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div className="tiers-box">
                  <div className="tier-title">Pack Leaders</div>
                  <div className="img-price-container">
                    <div className="white-circle mx-auto my-3 d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/dogs.svg" alt="Dogs"/>
                    </div>
                    <div className="price-range-box">
                    Subscriber
                  </div>
                  </div>
                </div>
                <div className="tiers-box-description">Sign up for Auto-Refill and get 8 points per dollar spent! </div>
              </div>

            </div>
          </div>
        </section>

        <section className="earn-points-section">
          <div className="container">
            <div className="row m-0 text-center">
              <h2 className="col-12">Ways to Earn Points</h2>
              <div className="col-12 d-none d-md-flex justify-content-between mt-6">
                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Purchase Icon.svg" alt="Purchase Icon"/>
                    </div>
                    <div>Make a Purchase</div>
                    <p>5-8 Points</p>
                  </div>
                </div>

                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Account Icon.svg" alt="Account Icon"/>
                    </div>
                    <div>Create Your Account</div>
                    <p>250 Points</p>
                  </div>
                </div>

                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Instagram Icon.svg" alt="Instagram Icon"/>
                    </div>
                    <div>Follow On Instagram</div>
                    <p>50 Points</p>
                  </div>
                </div>
              </div>

              <div className="col-12 d-none d-md-flex justify-content-between mt-6">
                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Thumbsup Icon.svg" alt="Thumbsup Icon"/>
                    </div>
                    <div>Like Us On Facebook</div>
                    <p>50 Points</p>
                  </div>
                </div>

                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Newsletter Icon.svg" alt="Newsletter Icon"/>
                    </div>
                    <div>Newsletter Sign Up</div>
                    <p>50 Points</p>
                  </div>
                </div>

                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Bithday Icon.svg" alt="Birthday Icon"/>
                    </div>
                    <div>Birthday</div>
                    <p>500 Points</p>
                  </div>
                </div>
              </div>

              <div className="col-12 d-none d-md-flex justify-content-around mt-6">
                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/Refer Friends Icon.svg" alt="Refer Friends Icon"/>
                    </div>
                    <div>Refer Friends</div>
                    <p>2000 Points</p>
                  </div>
                </div>

                <div className="col-4 text-center">
                  <div className="earn-points-box">
                    <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                      <img src="/img/landing-pages/flea-market/ChatDots.svg" alt="Chat Icon"/>
                    </div>
                    <div>Sign Up for SMS</div>
                    <p>75 Points</p>
                  </div>
                </div>
              </div>

              <div className="col-12 d-md-none earn-points-options-mobile">
                <Carousel className="earn-points-options-list" controls={false} interval={3000}>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Purchase Icon.svg" alt="Purchase Icon"/>
                      </div>
                      <div>Make a Purchase</div>
                      <p>5-8 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Account Icon.svg" alt="Account Icon"/>
                      </div>
                      <div>Create Your Account</div>
                      <p>250 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Instagram Icon.svg" alt="Instagram Icon"/>
                      </div>
                      <div>Follow On Instagram</div>
                      <p>50 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Thumbsup Icon.svg" alt="Thumbsup Icon"/>
                      </div>
                      <div>Like Us On Facebook</div>
                      <p>50 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Newsletter Icon.svg" alt="Newsletter Icon"/>
                      </div>
                      <div>Newsletter Sign Up</div>
                      <p>50 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Bithday Icon.svg" alt="Birthday Icon"/>
                      </div>
                      <div>Birthday</div>
                      <p>500 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/Refer Friends Icon.svg" alt="Refer Friends Icon"/>
                      </div>
                      <div>Refer Friends</div>
                      <p>2000 Points</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="earn-points-box">
                      <div className="white-circle mx-auto d-flex justify-content-center align-items-center">
                        <img src="/img/landing-pages/flea-market/ChatDots.svg" alt="Chat Icon"/>
                      </div>
                      <div>Sign Up for SMS</div>
                      <p>75 Points</p>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="join-flea-market-second-section">
          <div className="container">
            <div className="row m-0 text-center">
              <h2 className="col-12">Join Today to Start Earning Points</h2>
              <a href="https://portal.honestpaws.com/account/register#/" target="_blank" className="btn btn-white-primary col-11 col-md-6 col-lg-3 mx-auto my-4 my-md-3">Join Now</a>
              <p className="col-12 text-white">Already a Loyalty Member? <a href="https://portal.honestpaws.com/account/login" target="_blank" className="text-white"><b>Log in.</b></a></p>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default FleaMarketPage

export const FleaMarketPageQuery = graphql`
  query FleaMarketPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { eq: "landing-pages/flea-market/Loyalty-Page-og-image.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`